import { makeVar } from '@apollo/client';

import type { CreateVitaDocumentFileAttachmentsInput } from '@xing-com/crate-common-graphql-types';
import {
  VitaDocumentThemeTemplate,
  VitaDocumentThemeColor,
  VitaDocumentLanguage,
} from '@xing-com/crate-common-graphql-types';
import type { VitaDocumentFragment } from '@xing-com/crate-lebenslauf-graphql';

export type VitaDocumentType = VitaDocumentFragment &
  CreateVitaDocumentFileAttachmentsInput & {
    validation: { invalidFormInputNames: string[] };
    token?: string | null;
    language?: VitaDocumentLanguage | null;
    showAnschreibenBanner: boolean;
    suggestionTrackingToken?: string;
  };

const locale = global.window?.navigator?.language.slice(0, 2).toUpperCase();
const language =
  locale === VitaDocumentLanguage.En
    ? VitaDocumentLanguage.En
    : VitaDocumentLanguage.De;

export const vitaClientDocumentInitialValues: VitaDocumentType = {
  language,
  token: '',
  cv: {
    personalInformation: {
      firstName: '',
      lastName: '',
      phone: '',
      email: '',
      city: '',
      country: '',
      street: '',
      zip: '',
      birthdate: '',
      birthplace: '',
    },
    professionalExperience: [],
    educationExperience: [],
  },
  theme: {
    color: VitaDocumentThemeColor.DarkBlue,
    template: VitaDocumentThemeTemplate.Traditional,
  },
  validation: {
    invalidFormInputNames: [],
  },
  fileAttachments: {
    profileImage: {},
  },
  showAnschreibenBanner: false,
};

const clientVar = makeVar(vitaClientDocumentInitialValues);

export const sharedClientDocumentVariable = clientVar;
