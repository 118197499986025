import { makeVar } from '@apollo/client';

import {
  VitaDocumentLanguage,
  VitaDocumentThemeColor,
  VitaDocumentThemeTemplate,
} from '@xing-com/crate-common-graphql-types';
import type {
  CreateVitaDocumentFileAttachmentsInput,
  VitaCoverLetter,
} from '@xing-com/crate-common-graphql-types';

export type CoverLetterDocumentType = CreateVitaDocumentFileAttachmentsInput &
  VitaCoverLetter & {
    profileImage?: { uploadId?: string; delete?: boolean };
    validation: { invalidFormInputNames: string[] };
    token?: string | null;
  };

const locale = global.window?.navigator?.language.slice(0, 2).toUpperCase();

const language =
  locale === VitaDocumentLanguage.En
    ? VitaDocumentLanguage.En
    : VitaDocumentLanguage.De;

export const coverLetterClientDocumentInitialValues: CoverLetterDocumentType = {
  language,
  token: '',
  personalInformation: {
    firstName: '',
    lastName: '',
    phone: '',
    email: '',
    city: '',
    country: '',
    street: '',
    zip: '',
    birthdate: '',
    birthplace: '',
  },
  professionalExperience: [],
  educationExperience: [],
  jobDetails: {
    description: '',
    title: '',
  },
  fileAttachments: {
    profileImage: {},
  },
  content: [
    {
      type: 'paragraph',
      children: [
        {
          text: '',
        },
      ],
    },
  ],
  theme: {
    color: VitaDocumentThemeColor.DarkBlue,
    template: VitaDocumentThemeTemplate.TraditionalCoverLetter,
  },
  validation: {
    invalidFormInputNames: [],
  },
  skills: [],
};

const clientVarCoverLetter = makeVar(coverLetterClientDocumentInitialValues);

export const sharedCoverLetterClientDocumentVariable = clientVarCoverLetter;
